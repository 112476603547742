export const environment = {
  production: false,
  map_box_token: '',
  firebase: {
      apiKey: "AIzaSyANUT2TJvnISJZL3PW4xOAsMuU7xUfq5X4",
      authDomain: "ionic-olx-d3206.firebaseapp.com",
      projectId: "ionic-olx-d3206",
      storageBucket: "ionic-olx-d3206.appspot.com",
      messagingSenderId: "519451432286",
      appId: "1:519451432286:web:a57442ea34f786537f2bcf",
      measurementId: "G-G7D5LYG26S"
  },
  adminEmail: 'admin@admin.com',  
  stripe: {
    sk: ''
  },
};