import { Component, OnInit } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonService } from './services/common.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { PushNotifications } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { getDoc, setDoc, doc, getFirestore } from 'firebase/firestore';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Categories',
      url: '/categories',
      icon: 'grid'
    },
    {
      title: 'Subcategories',
      url: '/sub-categories',
      icon: 'list'
    },
    {
      title: 'Users',
      url: '/users',
      icon: 'people'
    },
    {
      title: 'Vehicle List',
      url: '/items',
      icon: 'list'
    },
    {
      title: 'Cities',
      url: '/cities',
      icon: 'location'
    },
    {
      title: 'Packages',
      url: '/package',
      icon: 'cube'
    },
    // {
    //   title: 'Subscriber',
    //   url: '/subscriber',
    //   icon: 'diamond'
    // },
    {
      title: 'Comments',
      url: '/feature-items',
      icon: 'albums'
    },
    {
      title: 'Loan Applications',
      url: '/loan',
      icon: 'cash'
    },
    {
      title: 'Notifications',
      url: '/notifications',
      icon: 'notifications'
    }
  ];

  public remoteToken: string = '';
  db = getFirestore();
  
  constructor(
    private platform: Platform,
    private menuCtrl: MenuController,
    private router: Router,
    private common: CommonService,
  ) 
  {
    // this.initializeApp();
  }

  // async initializeApp() { 
  //   if (localStorage.getItem('isLoggedInAdmins') === 'true') {
  //     this.router.navigateByUrl('/home', { skipLocationChange: true, replaceUrl: true });
  //     this.menuCtrl.enable(true); 
  //   } else {
  //     this.logout()
  //     this.router.navigateByUrl('/login', { skipLocationChange: true, replaceUrl: true });
  //     this.menuCtrl.enable(false)
  //   } 
  //    PushNotifications.requestPermissions().then((permission) => {
  //     if(permission.receive){
  //       PushNotifications.register();
  //     } else {
  //       console.log('No Permission')
  //     }
  //   }) 
  //   PushNotifications.addListener("registration", (token)=> {
  //     console.log(token.value)
  //   });
  //   this.getToken() 
  //   PushNotifications.addListener("pushNotificationReceived", (notification)=> {
  //    console.log(JSON.stringify(notification))
  //    const data = notification.data;
  //    //const title = notification.title;
  //    //const body = notification.body;
  //   })
  //   PushNotifications.register(); 
  //   this.platform.ready().then(() => {
  //     setTimeout(() => {
  //      SplashScreen.hide();
  //      }, 3000);
  //   })
  // }

  logout() {
    // this.menuCtrl.enable(false)
    // this.common.logout();
    localStorage.removeItem('isLoggedInAdmins');
    this.router.navigate(['/login']);
    // , { skipLocationChange: true, replaceUrl: true }
  }
  ngOnInit() { }

  getToken() {
    FCM.getToken()
      .then((result) => {
        this.remoteToken = result.token;
        localStorage.setItem('fcm', this.remoteToken)
      })
      .catch((err) => console.log(err));
  }
}
